import { SvgIcon } from "@mui/material";
import React from "react";

export default function InputSearchIcon({ ...props }) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1277_7822)">
          <path
            d="M18.9 16.776C20.4965 14.6424 21.2255 11.9832 20.9403 9.33375C20.6551 6.68427 19.3767 4.24121 17.3627 2.49634C15.3486 0.751469 12.7483 -0.165652 10.0852 -0.0704088C7.42209 0.0248339 4.89395 1.12537 3.00966 3.00966C1.12537 4.89395 0.0248339 7.42209 -0.0704088 10.0852C-0.165652 12.7483 0.751469 15.3486 2.49634 17.3627C4.24121 19.3767 6.68427 20.6551 9.33375 20.9403C11.9832 21.2255 14.6424 20.4965 16.776 18.9L21.876 24L24 21.88L18.9 16.776ZM10.5 18C9.01664 18 7.56659 17.5601 6.33322 16.736C5.09985 15.9119 4.13856 14.7406 3.5709 13.3701C3.00324 11.9997 2.85472 10.4917 3.14411 9.03682C3.4335 7.58196 4.1478 6.24559 5.19669 5.19669C6.24559 4.1478 7.58196 3.4335 9.03682 3.14411C10.4917 2.85472 11.9997 3.00324 13.3701 3.5709C14.7406 4.13856 15.9119 5.09985 16.736 6.33322C17.5601 7.56659 18 9.01664 18 10.5C17.9981 12.4886 17.2074 14.3951 15.8013 15.8013C14.3951 17.2074 12.4886 17.9981 10.5 18Z"
            fill="#A0A0A0"
          />
        </g>
        <defs>
          <clipPath id="clip0_1277_7822">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
