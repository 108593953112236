import { SvgIcon } from "@mui/material";
import React from "react";

export default function ClockIcon({ ...props }) {
  return (
    <SvgIcon
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 22.8271C18.0228 22.8271 22.5 18.35 22.5 12.8271C22.5 7.3043 18.0228 2.82715 12.5 2.82715C6.97715 2.82715 2.5 7.3043 2.5 12.8271C2.5 18.35 6.97715 22.8271 12.5 22.8271Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 6.82715V12.8271L16.5 14.8271"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
