import { SvgIcon } from "@mui/material";
import React from "react";

export default function QuestionMarkIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <svg>
        <path
          d="M12 17V16.9929M9.13733 9C9.51961 7.84083 10.6567 7 12 7C13.6568 7 15 8.27919 15 9.85714C15 12.106 12.5726 11.7539 12.0848 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          stroke="#686868"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
