import { SvgIcon } from "@mui/material";
import React from "react";

export default function CalendarOutlinedIcon({ ...props }) {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.09277 9.90445H20.9167"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.442 13.8097H16.4512"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0045 13.8097H12.0137"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.55818 13.8097H7.56744"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.442 17.6964H16.4512"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0045 17.6964H12.0137"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.55818 17.6964H7.56744"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0433 2.5V5.79078"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.96515 2.5V5.79078"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2383 4.0791H7.77096C4.83427 4.0791 3 5.71504 3 8.72213V17.7718C3 20.8261 4.83427 22.4999 7.77096 22.4999H16.229C19.175 22.4999 21 20.8545 21 17.8474V8.72213C21.0092 5.71504 19.1842 4.0791 16.2383 4.0791Z"
          stroke="#07A4FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
