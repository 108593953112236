import { Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { getToken, getUid } from "../../redux/authSlice";
import { Button } from "../../components";
import Notifications from "./notification";
import { getData } from "../../utils/firebase";
import DealershipInformation from "./dealership-information";
import Snackbar from "../../components/snackbar/snackbar";
import { validateMobileNumber } from "../../utils/utils.service";

const initialData = [
  {
    firstName: "",
    lastName: "",
    phoneNumber: "+1",
    role: "owner",
    callbackRequest: false,
    appointments: false,
    hotLeads: false,
    firstResponder: false,
    dealerAssistant: false,
    willBeRemoved: false,
    newLeads: false,
  },
];

export default function Dealership() {
  const [dealerShip, setDealership]: any = useState();
  const [notifications, setNotifications]: any = useState([]);
  const token = useSelector(getToken);
  const uid = useSelector(getUid);
  const [notify, setNotify] = useState({ message: "", open: false, type: "" });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [, setValidate] = useState(false);

  const handleGetData = (data: any) => {
    const { dealerName, dealerEmail, dealerPhone, dealerAddress } = data;
    setDealership({
      dealerName,
      dealerEmail,
      dealerPhone,
      dealerAddress,
    });
  };

  const handleGetNotifications = (data: any) => {
    if (!data) return;
    console.log(data);
    // Collect notifications from object keys
    const notificationsToAdd = Object.keys(data).map((key) => ({
      id: key, // Use the key as the unique identifier
      ...data[key], // Spread the notification data associated with the key
    }));
    setNotifications(() => {
      return [...notificationsToAdd];
    });
  };

  const checkNotificationStatus = () => {
    const shouldUpdates: [] = notifications
      .filter((notification: { id: any }) => notification.id)
      .map((item: any) => (item.willBeRemoved ? { id: item.id } : item));
    const shouldCreates: [] = notifications.filter(
      (notification: any) => !notification.id && !notification.willBeRemoved
    );

    return { shouldUpdates: shouldUpdates, shouldCreates: shouldCreates };
  };

  const getAllData = useCallback(() => {
    getData(`dealers/${uid}/info`).then((r: any) => handleGetData(r));
    getData(`dealers/${uid}/info/notification`).then((r: any) => handleGetNotifications(r));
  }, [uid]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const postDealershipInfo = async () => {
    const apiClient = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}/salesAgent-settingsApi`,
      headers: {
        Authorization: ` ${token}`,
        "Content-Type": "application/json",
      },
    });
    const checkDataStatus = checkNotificationStatus();

    const updateValue = [
      {
        section: "user_info",
        data: {
          address: dealerShip.dealerAddress,
          email: dealerShip.dealerEmail,
          phoneNumber: dealerShip.dealerPhone,
          name: dealerShip.dealerName,
        },
      },
      {
        section: "notification_data",
        data: checkDataStatus.shouldUpdates,
      },
    ];

    const postValue = [
      {
        section: "user_info",
        data: { ...dealerShip },
      },
    ];

    if (checkDataStatus.shouldCreates.length > 0)
      postValue.push({
        section: "notification_data",
        data: { notifs: checkDataStatus.shouldCreates },
      });

    try {
      if (checkDataStatus.shouldUpdates.length > 0) await apiClient.put("", updateValue);
      if (checkDataStatus.shouldCreates.length > 0) await apiClient.post("", postValue);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = ({ currentTarget: input }: any) => {
    const value = input.value;
    setDealership({ ...dealerShip, [input.name]: value });
  };

  const handleChangeNotification = ({ currentTarget: input }: any) => {
    const value = input.value;
    setNotifications({ ...notifications, [input.name]: value });
  };

  const handleMorePerson = () => {
    setNotifications((prevItems: any) => {
      return prevItems.length > 1 && prevItems[1].willBeRemoved === true
        ? [{ ...prevItems[0] }, { ...prevItems[1], willBeRemoved: false }]
        : prevItems.length > 1
          ? [...prevItems.filter((item: any) => item.id), ...initialData]
          : [...prevItems, ...initialData];
    });
  };

  const handleRemoveNotification = () => {
    setNotifications((prevItems: any) => {
      return prevItems[1].id
        ? [prevItems[0], { ...prevItems[1], willBeRemoved: true }]
        : [prevItems[0]];
    });
  };

  const postData = () => {
    if (!dealerShip.dealerName || !dealerShip.dealerPhone || !dealerShip.dealerAddress) return;
    setSubmitLoading(true);
    postDealershipInfo()
      .then(() => {
        setNotify({ message: "success", open: true, type: "" });
      })
      .catch((err) => {
        setNotify({ message: err.message || "error", open: true, type: "error" });
      })
      .finally(() => {
        setSubmitLoading(false);
        getAllData();
      });
  };

  if (!dealerShip) return <CircularProgress />;
  return (
    <>
      {dealerShip && (
        <DealershipInformation
          state={dealerShip}
          setState={setDealership}
          onChange={handleChange}
          onValidate={setValidate}
        />
      )}
      {notifications && dealerShip && (
        <Notifications
          state={notifications}
          setState={setNotifications}
          onChange={handleChangeNotification}
          onMorePerson={handleMorePerson}
          removeNotification={handleRemoveNotification}
          onValidate={setValidate}
        />
      )}
      {dealerShip && (
        <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1} mb={1}>
          <Button onClick={() => getAllData()} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={postData}
            disabled={
              dealerShip &&
              (!dealerShip.dealerName ||
                !dealerShip.dealerPhone ||
                !validateMobileNumber(dealerShip.dealerPhone) ||
                // // !dealerShip.email ||
                // // !validateEmail(dealerShip.email) ||
                !dealerShip.dealerAddress ||
                notifications
                  .filter((item: any) => !item.willBeRemoved)
                  .find(
                    (item: any) =>
                      !item.firstName || !item.lastName || !validateMobileNumber(item.phoneNumber)
                  ))
            }
          >
            {submitLoading ? <CircularProgress color="info" size={16} /> : "Save Change"}
          </Button>
        </Box>
      )}
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
    </>
  );
}
