import React from "react";
import Button from "./button";
import Animation from "../../components/animation";
import animationData from "../../assets/lottie/voice.json";

export default function OutlineAnimationButton({ title, ...props }: any) {
  return (
    <Button
      variant="cover"
      color="primary"
      style={{
        width: "240px",
        height: "50px",
        padding: "1px",
        border: "double 1px transparent",
        borderRadius: "58px",
        backgroundImage:
          "linear-gradient(white, white), linear-gradient(to right, #2981ef, #e820d3)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
      }}
      sx={{
        fontWeight: 700,
        boxShadow: "none",
        padding: ".75rem 2rem",
        overflow: "hidden",
        color: "black",
      }}
      {...props}
    >
      <Animation
        animationData={animationData}
        autoplay
        loop
        style={{
          height: 40,
          width: 40,
          marginRight: "5px",
        }}
      />
      {title}
    </Button>
  );
}
