import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import ArrowDownIcon from "../../assets/icon/arrow-down";

export default function Selector({
  label,
  data,
  width,
  withCustomMenuItems,
  fullWidth,
  value,
  ...rest
}: any) {
  return (
    <FormControl size="small" sx={{ width: fullWidth ? "100%" : "fit-content" }}>
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      <Select
        IconComponent={ArrowDownIcon}
        value={value}
        label={label}
        labelId="demo-simple-select-label"
        displayEmpty
        renderValue={(value: any) =>
          withCustomMenuItems ? data.find((item: any) => item?.value === value)?.name : value
        }
        sx={{ width: width ? width : "100%", height: "40px" }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "200px",
              overflowY: "auto",
            },
          },
        }}
        {...rest}
      >
        {withCustomMenuItems
          ? data?.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.labelComponent()}
              </MenuItem>
            ))
          : data?.map((i: any, index: number) => (
              <MenuItem key={index} value={i}>
                {i}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}
