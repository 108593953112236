import { keyframes } from "@emotion/react";

export default Object.freeze({
  CHAT_DROPZONE_ACCEPTED_FILES: "image/*,application/pdf",
  MAX_FILE_SIZE: 5 * 1024 * 1024,
  TIMESTAMP: 30 * 60 * 1000,
});

export const navHeight = 8;
export const navHeightPx = "64px";
export const navAndBottomHeightPx = "120px";
export const bottomHeight = 56;
export const rotate45deg = keyframes`
  0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(0.75, 1.25, 1);
	}

	40% {
		transform: scale3d(1.25, 0.75, 1);
	}

	50% {
		transform: scale3d(0.85, 1.15, 1);
	}

	65% {
		transform: scale3d(1.05, 0.95, 1);
	}

	75% {
		transform: scale3d(0.95, 1.05, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
`;
