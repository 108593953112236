import "react-international-phone/style.css";

import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { forwardRef } from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import InputError from "../input-error";

// Wrap MuiPhone with forwardRef
const MuiPhone = forwardRef(({ value, onChange, error, label, ...restProps }: any, ref) => {
  const { inputValue, handlePhoneValueChange, country, setCountry } = usePhoneInput({
    defaultCountry: "us",
    value,
    disableDialCodeAndPrefix: true,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone);
    },
  });

  return (
    <FormControl fullWidth={true}>
      {label && (
        <Typography fontWeight={500} mb={1} fontSize={"14px"}>
          {label}
        </Typography>
      )}
      <TextField
        ref={ref} // Pass the ref to the TextField
        value={inputValue}
        onChange={handlePhoneValueChange}
        type="tel"
        sx={{
          background: (theme) => theme.palette.background.paper,
          height: "43px",
          borderRadius: "2rem",
          "& .MuiInputBase-root": {
            height: "100%",
            borderRadius: "2rem",
          },
          borderColor: error ? "#E84933" : (theme: any) => theme.palette.secondary[200],
          fontSize: "14px",
          "& input::placeholder": {
            fontSize: "13px !important",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: error ? "#E84933" : (theme: any) => theme.palette.secondary[200],
            },
            "&.Mui-focused fieldset": {
              borderColor: error ? "#E84933" : (theme: any) => theme.palette.secondary[200],
              borderWidth: "1px",
            },
            "&:hover fieldset": {
              borderColor: error ? "#E84933" : (theme: any) => theme.palette.secondary[200],
              borderWidth: "1px",
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: "2px", marginLeft: "-8px" }}>
              <Select
                MenuProps={{
                  style: {
                    height: "300px",
                    width: "360px",
                    top: "10px",
                    left: "-34px",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  width: "max-content",
                  height: "46px",
                  borderRadius: "8px",
                  fieldset: {
                    display: "none",
                  },
                  '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                      display: "block",
                    },
                  },
                  ".MuiSelect-select": {
                    padding: "8px",
                    paddingRight: "24px !important",
                    height: "100%",
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => <FlagImage iso2={value} style={{ display: "flex" }} />}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage iso2={country.iso2} style={{ marginRight: "8px" }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="#8F92A1">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps}
      />
      {error && <InputError message="Please enter your phone number" />}
    </FormControl>
  );
});

// Set the display name for better debugging
MuiPhone.displayName = "MuiPhone";

export default MuiPhone;
