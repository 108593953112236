import { SvgIcon } from "@mui/material";
import React from "react";

export default function ScheduleIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path d="M19.9145 3.78699H17.7507V2.28699C17.7507 1.87299 17.4147 1.53699 17.0007 1.53699C16.5868 1.53699 16.2507 1.87299 16.2507 2.28699V3.78699H8.74925V2.28699C8.74925 1.87299 8.41325 1.53699 7.99925 1.53699C7.58525 1.53699 7.24925 1.87299 7.24925 2.28699V3.78699H5.0855C2.96675 3.78699 1.25 5.50449 1.25 7.62249V19.4507C1.25 21.2425 2.4845 22.7515 4.148 23.1707C4.62275 23.29 5.0855 22.9307 5.0855 22.441C5.0855 22.0855 4.8305 21.7997 4.48625 21.7082C3.488 21.4427 2.75 20.5307 2.75 19.4507V7.62324C2.75 6.33324 3.7955 5.28774 5.0855 5.28774H7.24925V6.78774C7.24925 7.20174 7.58525 7.53774 7.99925 7.53774C8.41325 7.53774 8.74925 7.20174 8.74925 6.78774V5.28774H16.25V6.78774C16.25 7.20174 16.586 7.53774 17 7.53774C17.414 7.53774 17.75 7.20174 17.75 6.78774V5.28774H19.9137C21.2038 5.28774 22.2493 6.33324 22.2493 7.62324V11.4062C22.2493 11.8202 22.5853 12.1562 22.9993 12.1562C23.4132 12.1562 23.7493 11.8202 23.7493 11.4062V7.62324C23.7493 5.50449 22.0317 3.78774 19.9137 3.78774L19.9145 3.78699Z" />
      <path d="M5.75 11.0432H12.272C12.686 11.0432 13.022 10.7072 13.022 10.2932C13.022 9.87921 12.686 9.54321 12.272 9.54321H5.75C5.336 9.54321 5 9.87921 5 10.2932C5 10.7072 5.336 11.0432 5.75 11.0432Z" />
      <path d="M5.75 14.9275C6.16421 14.9275 6.5 14.5917 6.5 14.1775C6.5 13.7633 6.16421 13.4275 5.75 13.4275C5.33579 13.4275 5 13.7633 5 14.1775C5 14.5917 5.33579 14.9275 5.75 14.9275Z" />
      <path d="M18.4076 10.5925C17.8301 10.0742 17.0306 9.94747 16.3226 10.264C15.6139 10.5797 15.1736 11.2585 15.1736 12.034V12.9362C12.7444 13.0802 10.7996 13.9217 9.39489 15.46C7.28514 17.7707 6.99414 20.9815 6.98439 22.4852C6.98139 22.9412 7.36539 23.3027 7.82064 23.2825C8.19189 23.266 8.49489 22.9922 8.56464 22.6277C8.67564 22.0487 8.94939 21.5597 9.39339 21.1442C10.8934 19.7432 13.8334 19.5737 15.1729 19.573V20.1865C15.1729 20.9627 15.6131 21.6407 16.3219 21.9565C16.5806 22.072 16.8514 22.1282 17.1184 22.1282C17.5841 22.1282 18.0401 21.9565 18.4076 21.6272L22.9414 17.5502C23.3494 17.1835 23.5834 16.6577 23.5834 16.108C23.5834 15.5597 23.3494 15.034 22.9414 14.668L18.4069 10.5902L18.4076 10.5925Z" />
    </SvgIcon>
  );
}
