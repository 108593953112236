import React from "react";
import { Box, Checkbox, FormControlLabel, SelectChangeEvent, Typography } from "@mui/material";

import { useState } from "react";

import { generateClock } from "../../utils/utils.service";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Selector from "../../components/select/select";
import dayjs from "dayjs";
import Button from "../button/button";
import EditableSelect from "./editable-select";

const hourRegex = /^(?:1[0-2]|[1-9]):([0-5][0-9])$/;

const turnToTwoDigits = (num: number) => (num === 0 ? "00" : num < 10 ? `0${num}` : `${num}`);

export default function Scheduler({
  onSave,
  onClose,
  timestamp,
  type,
  onCancel,
  notifyCustomer,
  setNotifyCustomer,
}: any) {
  console.log(timestamp);
  const [date, setDate] = useState(timestamp ? new Date(timestamp) : new Date());
  const [morning, setMorning] = useState(
    timestamp
      ? new Date(timestamp).getHours() > 12
        ? "PM"
        : "AM"
      : new Date().getHours() > 12
        ? "PM"
        : "AM"
  );
  const [hour, setHour] = useState(
    timestamp
      ? (new Date(timestamp).getHours() % 12) +
          ":" +
          turnToTwoDigits(new Date(timestamp).getMinutes())
      : new Date()
          .toLocaleString("en-US", { hour: "numeric", minute: "2-digit", hour12: true })
          .slice(0, -3)
  );

  const generateTimestamp = () => {
    const [hourPart, minutePart] = hour.split(":").map((num) => parseInt(num, 10));
    const newDate = new Date(date);
    let newHour = hourPart;
    if (morning === "PM" && hourPart !== 12) {
      newHour += 12;
    } else if (morning === "AM" && hourPart === 12) {
      newHour = 0;
    }
    newDate.setHours(newHour, minutePart, 0, 0);
    return newDate;
  };

  const handleSave = () => {
    const newDate = generateTimestamp();
    onSave(newDate.getTime());
    onClose();
  };

  const renderContent = (
    <Box
      sx={{
        backgroundColor: (theme: any) => theme.bg[100],
        // marginX: "15px",
        borderRadius: "20px",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingY: "20px",
        paddingX: "10px",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme: any) => theme.bg.paper,
          width: "fit-content",
          borderRadius: "16px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            disablePast
            showDaysOutsideCurrentMonth={true}
            sx={{
              "& .MuiButtonBase-root  .MuiPickersDay-root .Mui-selected  ": {
                backgroundColor: "#07A4FC",
                color: "white",
                borderRadius: "5px",
              },
              "& .MuiButtonBase-root": {
                borderRadius: "5px",
                color: "#7B7B7B",
              },
              "& .MuiPickersDay-dayOutsideMonth": {
                color: "#d4d4d4",
              },
            }}
            // defaultValue={dayjs(date)}
            value={dayjs(date)}
            onChange={(d) => {
              if (d) {
                setDate(d);
              }
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <EditableSelect hour={hour} setHour={setHour} options={generateClock()} />
        <Box
          sx={{
            padding: "10px 0",
            height: "41px",
            // borderTop: "1px solid #E5E5E5",
            // borderBottom: "1px solid #E5E5E5",

            backgroundColor: (theme: any) => theme.bg.paper,
          }}
        >
          <Box sx={{ backgroundColor: "#E5E5E5", width: "2px", height: "100%" }}></Box>
        </Box>
        <Selector
          data={["AM", "PM"]}
          width={85}
          sx={{
            // borderRadius: "20px",
            borderRadius: "0 20px 20px 0",
            border: "none !important",
            minWidth: "85px",
            backgroundColor: (theme: any) => theme.bg.paper,
            "&>.MuiOutlinedInput-notchedOutline": {
              border: "none !important",
              borderColor: "#E5E5E5",
              // "&>:hover": { borderColor: "#E5E5E5" },
              // borderLeft: "1px solid rgba(212, 212, 212, 0.58) !important",
            },
          }}
          value={morning}
          onChange={(e: SelectChangeEvent) => setMorning(e.target.value)}
        />
      </Box>
      {(timestamp ||
        (date &&
          hour &&
          morning &&
          hourRegex.test(hour) &&
          generateTimestamp().getTime() > new Date().getTime())) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            padding: "16px 8px",
            alignItems: "center",
            border: "1px solid #07A4FC",
            backgroundColor: "rgba(206, 235, 248, 0.25)",
            borderRadius: "12px",
            color: "#07A4FC",
          }}
        >
          <Typography
            sx={{ color: "#07A4FC", fontWeight: "700", fontSize: "13px", display: "block" }}
          >
            <span
              style={{ display: type === "appointment" && timestamp ? "inline-block" : "inline" }}
            >
              {" "}
              {type === "appointment"
                ? "Appointment scheduled for: "
                : "Scheduled to be sent on: "}{" "}
            </span>
            {new Date(date).toLocaleDateString("en", { month: "long" })} {new Date(date).getDate()}{" "}
            at {hour} {morning}
          </Typography>
          {type === "appointment" && timestamp && (
            <Button
              variant="outlined"
              color="error"
              size="small"
              sx={{ minWidth: "165px", height: "fit-content" }}
              onClick={onCancel}
            >
              Cancel Appointment
            </Button>
          )}
        </Box>
      )}
      {false && type === "appointment" && (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={notifyCustomer}
                onChange={(e) => setNotifyCustomer(e.target.checked)}
              />
            }
            label={"Notify customer"}
            labelPlacement="end"
            name={"notify customer"}
          />
        </Box>
      )}
    </Box>
  );

  const renderActions = (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: 2,
          width: "100%",
          gap: "10px",
        }}
      >
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          disabled={!hourRegex.test(hour) || generateTimestamp().getTime() < new Date().getTime()}
          onClick={handleSave}
          variant="contained"
        >
          {timestamp ? "Reschedule" : " Save"}
        </Button>
      </Box>
    </React.Fragment>
  );
  return (
    <Box>
      {renderContent}
      {renderActions}
    </Box>
  );
}
