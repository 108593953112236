import { Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import PrivateRoute from "../utils/private-router";
import React, { lazy } from "react";
import PageLoading from "../components/pageLoading/pageLoading";
import NotFound from "../components/not-found";
import TrainingRequest from "../pages/training-request";
import Dealership from "../sections/setting/dealership";
import BusinessHours from "../sections/setting/business-hours";
import Lead from "../sections/setting/lead";

// Lazy load the component
const LazyInbox = lazy(() => import("../sections/dashboard/inbox"));
const LazyHolidays = lazy(() => import("../sections/setting/holidays"));
// const LazyAIActiveHours = lazy(() => import("../sections/setting/ai-active-hours"));
const LazyTechnicalQuestions = lazy(() => import("../sections/setting/technical-questions"));
const LazyAISetting = lazy(() => import("../sections/setting/ai-setting"));
const LazyEmailMessage = lazy(() => import("../sections/setting/email"));
const LazySMSMessage = lazy(() => import("../sections/setting/sms"));
const LazyAppointment = lazy(() => import("../pages/appointment"));
const LazyReport = lazy(() => import("../pages/report"));
const LazySetting = lazy(() => import("../pages/setting"));
const LazyCars = lazy(() => import("../pages/cars"));

export default function Router() {
  return (
    <Routes>
      <Route path="" element={<PrivateRoute />}>
        <Route
          path=""
          element={
            <React.Suspense fallback={<PageLoading />}>
              <LazyInbox />
            </React.Suspense>
          }
        />
        <Route
          path=":id"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <LazyInbox />
            </React.Suspense>
          }
        />
        <Route
          path="appointment"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <LazyAppointment />
            </React.Suspense>
          }
        />
        <Route
          path="report"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <LazyReport />
            </React.Suspense>
          }
        />
        <Route
          path="training-request"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <TrainingRequest />
            </React.Suspense>
          }
        />
        <Route
          path="cars"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <LazyCars />
            </React.Suspense>
          }
        />
        <Route
          path="setting"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <LazySetting />
            </React.Suspense>
          }
        >
          <Route path="dealership" element={<Dealership />} />
          <Route path="business-hours" element={<BusinessHours />} />
          <Route path="lead" element={<Lead />} />
          <Route path="holidays" element={<LazyHolidays />} />
          <Route path="AI-message" element={<LazyAISetting />} />
          <Route path="technical-questions" element={<LazyTechnicalQuestions />} />
          {/* <Route path="AI-active-hours" element={<LazyAIActiveHours />} /> */}
          <Route path="email-message" element={<LazyEmailMessage />} />
          <Route path="sms-message" element={<LazySMSMessage />} />
        </Route>
      </Route>

      <Route
        path="login"
        element={
          <React.Suspense fallback={<PageLoading />}>
            <Login />
          </React.Suspense>
        }
      />
      {/* Place the catch-all route at the end */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
