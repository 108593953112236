import { Alert, Box, Collapse } from "@mui/material";
import * as React from "react";
// import IconButton from "../icon-button/icon-button";
// import CloseIcon from "@mui/icons-material/Close";

const CustomSnackbar = ({ open, handleClose, message, type, duration, ...rest }: any) => {
  // Auto-close alert after a specified duration
  React.useEffect(() => {
    if (!open) return;
    const timer = setTimeout(
      () => {
        handleClose();
      },
      duration ? duration : 3000
    );

    return () => {
      clearTimeout(timer); // Cleanup the timer on unmount
    };
  }, [duration, handleClose, open]);

  return (
    <Box
      sx={{
        width: "100%",
        display: open ? "block" : "none",
        "&.MuiAlert-action": { paddingLeft: "0 !important" },
      }}
    >
      <Collapse in={open}>
        <Alert
          severity={type ? type : "success"}
          // action={
          //   <IconButton
          //     aria-label="close"
          //     color="inherit"
          //     size="small"
          //     sx={{
          //       paddingLeft: "0 !important",
          //       "&:hover": {
          //         backgroundColor: "transparent !important",
          //       },
          //     }}
          //     onClick={() => {
          //       handleClose();
          //     }}
          //     icon={<CloseIcon fontSize="inherit" color="error" />}
          //   />
          // }
          sx={{
            mb: 2,
            "&.MuiAlert-action": {
              paddingLeft: "0",
              "&.MuiPaper>root-MuiAlert-root": {
                backgroundColor: "blue !important",
              },
            },
          }}
          {...rest}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default CustomSnackbar;
