export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function getWeekDayFromTimestamp(timestamp: number) {
  return weekdays[new Date(timestamp).getDay()];
}

export function getDayAndMonthFromTimestamp(timestamp: number) {
  return new Date(timestamp).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
}

export function get12HourFromTimestamp(timestamp: number) {
  return new Date(timestamp).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
}

function formatTimeFromTimestamp(timestamp: any) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const period = hours < 12 ? "AM" : "PM";

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function formatDateFrom(timestamp: any, time?: boolean, justDate?: boolean) {
  const date = new Date(String(timestamp).length === 10 ? timestamp * 1000 : timestamp);

  // Format the date and time
  return time
    ? `${date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })}`
    : justDate
      ? `${date.toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric" })}`
      : `${date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })} - ${date.toLocaleString("en-US", { month: "long", day: "numeric" })}`;
}

function getOrdinalSuffix(day: number) {
  if (day > 3 && day < 21) return "th"; // Catch 11th-13th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
export function formatHour(timestamp: any) {
  const date = new Date(String(timestamp).length === 10 ? timestamp * 1000 : timestamp);

  // Format the date and time
  return `${date.toLocaleString("en-US", { hour: "2-digit", minute: "2-digit", hourCycle: "h23" })}`;
}

export default function getMinuteSecondFromTS(timestamp: any) {
  if (timestamp < 0) return "00:00";
  // const date = new Date(String(timestamp).length === 10 ? timestamp * 1000 : timestamp);
  const date = new Date(timestamp);
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
}

export function formatDate(timestamp: string, status?: string) {
  const date = new Date(timestamp);
  if (status) {
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";

    // Format hour in 12-hour format
    const formattedHour = hour % 12 || 12; // Convert to 12-hour format
    const formattedMinute = minute < 10 ? "0" + minute : minute;

    // Get the ordinal suffix
    const suffix = getOrdinalSuffix(day);

    return `${month} ${day}${suffix}, at ${formattedHour}:${formattedMinute} ${ampm}`;
  } else
    return `${date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })} - ${date.toLocaleString("en-US", { month: "long", day: "numeric" })}`;
}

function formatMonthFromTimestamp(timestamp: any) {
  const dateObj = new Date(timestamp);
  const options: any = {
    month: "long",
    day: "numeric",
    timeZone: getUserTimeZone(),
  };
  return dateObj.toLocaleString("en-US", options);
}

function getUserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function convertTimestamp(timestamp: any) {
  const dateObj = new Date(timestamp);
  const options: any = {
    weekday: "long",
    // year: 'numeric',
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: 'numeric',
    timeZone: getUserTimeZone(),
  };
  return dateObj.toLocaleString("en-US", options);
}

const isToday = (timestamp: any) => {
  const today = new Date();
  const date = new Date(timestamp);
  return today.toDateString() === date.toDateString();
};

const sortUsersByTimestampRange = (users: any[]) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const sortedUsers: any = {
    today: [],
    yesterday: [],
    previous7days: [],
    previous30days: [],
    previousMonth: [],
  };

  users.forEach((user) => {
    const lastMessageTimestamp = new Date(user?.lastMessage?.timestamp);

    if (lastMessageTimestamp.toDateString() === today.toDateString()) {
      sortedUsers.today.push(user);
    } else if (lastMessageTimestamp.toDateString() === yesterday.toDateString()) {
      sortedUsers.yesterday.push(user);
    } else if (lastMessageTimestamp > sevenDaysAgo && lastMessageTimestamp <= yesterday) {
      sortedUsers.previous7days.push(user);
    } else if (lastMessageTimestamp > thirtyDaysAgo && lastMessageTimestamp <= yesterday) {
      sortedUsers.previous30days.push(user);
    } else if (lastMessageTimestamp < firstDayOfCurrentMonth) {
      sortedUsers.previousMonth.push(user);
    }
  });

  return sortedUsers;
};

export {
  isToday,
  formatTimeFromTimestamp,
  convertTimestamp,
  formatMonthFromTimestamp,
  sortUsersByTimestampRange,
};
