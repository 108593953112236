import React, { useState } from "react";

import { Button } from "../../components";
// import axios from "axios";
import Dialog from "../../components/dialog/dialog";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledInput from "../../components/input/controlled-input";
import VoiceImageSection from "./voice-image-section";
import Snackbar from "../../components/snackbar/snackbar";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../hooks/use-media-query";
import MuiPhone from "../../components/input/mui-phone-input";
import { handlePush } from "../../utils/firebase";

const schema = yup.object().shape({
  fullName: yup
    .string()
    .required("Full name is required")
    .min(3, "Full name must be at least 3 characters long")
    .max(20, "Full name must be at maximum 20 characters long"),
  phoneNumber: yup.string().required("Phone number is required"),
  email: yup.string().required("Email is required").email("Invalid email"),
});

interface FormInputs {
  fullName: string;
  phoneNumber: string;
  email: string;
}

const defaultValues: FormInputs = {
  fullName: "",
  email: "",
  phoneNumber: "",
};

const voiceSections = [
  {
    title: "Human like conversations",
    image: "Frame1",
    content: (
      <Typography fontSize="14px">
        With this feature,{" "}
        <Typography display="inline" color="#AF17F5" fontWeight="bold">
          A.I. agent
        </Typography>{" "}
        calls your customers who are not engaged with text and communicate with them like a real
        human!
      </Typography>
    ),
  },
  {
    title: "10X your lead engagement",
    image: "Frame2",
    content: (
      <Typography>
        <Typography display="inline" color="#AF17F5" fontWeight="bold">
          A.I. agent
        </Typography>{" "}
        would engage your leads and provide detailed information and{" "}
        <Typography display="inline" color="#AF17F5">
          book appointments
        </Typography>
        . It would also transfer calls to dealership if needed.
      </Typography>
    ),
  },
  {
    title: "All calls recorded and monitored",
    image: "Frame3",
    content: (
      <Typography>
        Dealer can also listen in to the communication after the call is finished or read the{" "}
        <Typography display="inline" color="#AF17F5" fontWeight="bold">
          script
        </Typography>
        /
        <Typography display="inline" color="#AF17F5" fontWeight="bold">
          summary
        </Typography>
        .
      </Typography>
    ),
  },
];

export default function Voice({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  // Selector
  // const token = useSelector(getToken);
  const uid = useSelector(getUid);

  const [notify, setNotify] = useState({ message: "", open: false, type: "" });

  const { isCustomLayout } = useResponsive();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  // const sendData = async (name: string, email: string, phone_number: string) => {
  //   // Create an Axios instance with a base URL
  //   const apiClient = axios.create({
  //     baseURL: process.env.REACT_APP_BASE_URL + `/salesAgent-settingsApi`,
  //     headers: {
  //       Authorization: ` ${token}`, // Replace with your actual token
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const value = [
  //     {
  //       section: "voice_info",
  //       data: { name, email, phone_number },
  //     },
  //   ];
  //   try {
  //     await apiClient.post("", value);
  //     setNotify({ message: "success", open: true, type: "" });
  //   } catch (error: any) {
  //     setNotify({ message: error.response?.data?.error, open: true, type: "error" });
  //   }
  // };

  const onSubmit = () => {
    const { fullName, email, phoneNumber }: any = getValues();
    const data = {
      fullName,
      email,
      phoneNumber,
    };
    handlePush(`AIWaitlist/${uid}/`, data).then(() => {
      handleClose();
      reset();
    });
    // sendData(fullName, email, phoneNumber)
    //   .then((res) => {
    //     console.log(res);
    //     handleClose();
    //     reset();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // console.log(fullName, email, phoneNumber);
  };

  const renderContent = (
    <Grid display="flex" width={"100%"} justifyContent={"flex-start"} flexDirection="row">
      <IconButton
        style={{ position: "absolute", top: "30px", right: "30px" }}
        aria-label="close"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid
        item
        xs={8}
        padding={0}
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          flexDirection: "column",
          gap: 1,
        }}
        style={{
          paddingTop: "20px",
          backgroundImage: "url(/images/dash-line.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "107% 135%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#B985FB",
            display: "flex",
            justifyContent: "flex-end",
            color: "white",
            width: "80%",
            fontSize: "48px",
            fontWeight: "bold",
            padding: "15px 50px 15px 0",
            lineHeight: "50px",
          }}
        >
          Drivee&apos;s A.I. voice is <br></br> coming soon!
        </Box>
        <Box display="flex" flexDirection="column" gap="40px">
          {voiceSections.map((section, index) => (
            <VoiceImageSection
              align={index % 2 === 0 ? "left" : "right"}
              key={index}
              image={section.image}
              title={section.title}
            >
              {section.content}
            </VoiceImageSection>
          ))}
        </Box>
      </Grid>
      {!isCustomLayout && (
        <Grid
          item
          xs={4}
          width="70%"
          style={{
            backgroundImage: "url(/images/bg-phone.svg)",
            backgroundRepeat: "no-repeat",
            // backgroundSize: "120% 100%",
            backgroundPosition: "right bottom",
          }}
        >
          {" "}
        </Grid>
      )}
    </Grid>
  );

  //form section
  const renderActions = (
    <Box
      display={"flex"}
      margin="0 30px 20px 30px"
      paddingTop="50px"
      paddingBottom="30px"
      borderRadius="20px"
      style={{
        backgroundImage: "url(/images/bg-form.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      flexDirection={"column"}
      justifyContent={"center"}
      width={"100%"}
      // gap={1}
    >
      <Grid display="flex" alignItems="center" flexDirection="column">
        <Grid item xs={8} display="flex" justifyContent="center" container p={1}>
          <Grid item xs={12} fontWeight="bold" p={1} fontSize="18px">
            Join the waitlist below:
          </Grid>
          <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="fullName"
              render={({ field }) => (
                <ControlledInput
                  error={!!errors.fullName}
                  id="fullName"
                  label="Full Name *"
                  placeholder="Enter Your Full Name"
                  type="text"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <ControlledInput
                  error={!!errors.email}
                  id="Email"
                  label="Email *"
                  placeholder="Enter Your Email"
                  type="email"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <MuiPhone
                  label={"Phone Number *"}
                  id="phone_number"
                  placeholder="Enter your phone number"
                  error={!!errors.phoneNumber}
                  // error={validateMobileNumber(state?.phone_number) ? "" : "error"}
                  style={{ backgroundColor: "white" }}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
        <Button
          color="primary"
          variant="outlined"
          sx={{}}
          onClick={() => {
            reset();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button color="primary" type="submit" variant="contained">
          Submit
        </Button>
      </Box>
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
    </Box>
  );
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onSubmit={handleSubmit(onSubmit)}
        handleClose={handleClose}
        renderActions={renderActions}
        renderContent={renderContent}
        showCloseButton={false}
        noPadding
      />
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
    </>
  );
}
