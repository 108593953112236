import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Radio from "@mui/material/Radio";
import ControlledInput from "../../components/input/controlled-input";
import MuiPhone from "../../components/input/mui-phone-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddNotificationSchema } from "../../schemas/add-notification.schema";

const notifOptions = [
  { name: "callbackRequest", label: "Callback Request" },
  { name: "dealerAssistant", label: "Dealership Assistant Request" },
  { name: "appointments", label: "Appointment" },
  { name: "hotLeads", label: "Hot Leads" },
  { name: "firstResponder", label: "First Responder" },
  { name: "newLeads", label: "New Leads" },
];

interface FormInputs {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export default function AddNotification({ state, setState, handleChange, index }: any) {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    defaultValues: useMemo(() => {
      return {
        firstName: state[index].firstName,
        lastName: state[index].lastName,
        phoneNumber: state[index].phoneNumber,
      };
    }, [index, state]),
    mode: "onChange",
    resolver: yupResolver(AddNotificationSchema),
  });

  useEffect(() => {
    const subscription = watch(({ firstName, phoneNumber, lastName }: any) => {
      setState((prevState: any) => {
        const updatedItems = [...prevState];
        updatedItems[index] = {
          ...updatedItems[index],
          firstName,
          phoneNumber,
          lastName,
        };
        return updatedItems;
      });
    });
    return () => subscription.unsubscribe();
  }, [index, setState, state, watch]);

  return (
    <Box>
      <Grid container justifyContent={"left"} spacing={3}>
        <Grid item md={7} xs={12} display="flex" flexDirection="column" gap="20px">
          <Box display={"flex"} gap="20px">
            <Box width="100%">
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <ControlledInput
                    error={errors.firstName && errors.firstName.message}
                    id="firstName"
                    label="First Name *"
                    placeholder="Enter your first name"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>
            <Box width="100%">
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <ControlledInput
                    error={errors.lastName && errors.lastName.message}
                    id="lastName"
                    label="Last Name *"
                    placeholder="Enter your last name"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} gap={1}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <MuiPhone
                    // value={state?.phone_number}
                    label={"Phone Number *"}
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    // error={errors.phoneNumber?.message}
                    // onChange={(e: any) => {
                    //   setState({ ...state, phone_number: e });
                    //   if (validateMobileNumber(e)) onValidate(true);
                    //   else onValidate(false);
                    // }}
                    error={
                      // state[index].phone_number.length > 0 &&
                      // !validateMobileNumber(state[index].phone_number)
                      errors.phoneNumber && errors.phoneNumber.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ mt: 1 }}>
                <FormLabel>
                  <Typography fontWeight={500} mb={1} variant="body1">
                    Role
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="owner"
                    name="role"
                    control={<Radio />}
                    label="Owner"
                    checked={state[index].role === "owner"}
                    onChange={handleChange(index)} // Pass the index here
                  />
                  <FormControlLabel
                    value="staff"
                    control={<Radio />}
                    name="role"
                    label="Staff"
                    checked={state[index].role === "staff"}
                    onChange={handleChange(index)} // Pass the index here
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box display={"flex"} alignItems={"baseline"} flexDirection={"column"}>
            <Typography variant="body1" fontWeight={500}>
              Notification Option
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2,auto)",
                alignItems: "self-end",
                alignSelf: "stretch",
              }}
            >
              {notifOptions.map((option) => (
                <FormControlLabel
                  key={option.name}
                  control={<Checkbox sx={{ pl: 0 }} />}
                  label={option.label}
                  labelPlacement="end"
                  checked={state[index][option.name]}
                  name={option.name}
                  onChange={handleChange(index)} // Pass the index here
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {state?.filter((item: any) => !item.willBeRemoved).length > 1 && index === 0 && (
        <Divider sx={{ my: 2 }} />
      )}
    </Box>
  );
}
