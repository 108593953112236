import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AllIcon from "../assets/icon/all-icon";

import { useDispatch, useSelector } from "react-redux";
import { getSortValue, sortValueSet } from "../redux/userSlice";
import { Box, Divider, Typography, styled } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useLocation, useNavigate } from "react-router";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import DirectionsCarRoundedIcon from "@mui/icons-material/DirectionsCarRounded";
import ContactUs from "../sections/dashboard/contact-us";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ModelTrainingRoundedIcon from "@mui/icons-material/ModelTrainingRounded";
import AppointmentIcon from "../assets/icon/appointment-icon";
import HotIcon from "../assets/icon/hot";
import ReengagedIcon from "../assets/icon/reengaged";
import ContactAttemptedIcon from "../assets/icon/contact-attempted-icon";
import StarFillIcon from "../assets/icon/star-fill";
import { useResponsive } from "../hooks/use-media-query";
import Feedback from "../sections/dashboard/feedback";

const CustomListItemButton = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  // width: 58,
  width: "100%",
  height: 58,
  // borderRadius: 12,
  cursor: "pointer",
  alignItems: "center",
}));

const CustomListItemIcon = styled(ListItemIcon)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "center",
}));

const LeftSidebar = ({ openSidebar }: any) => {
  const dispatch = useDispatch();
  const sortValue = useSelector(getSortValue);
  const navigate = useNavigate();
  const [openFeedBackDialog, setOpenFeedBackDialog] = React.useState(false);

  const { isCustomLayout } = useResponsive();

  const { pathname } = useLocation();
  const [contactOpen, setOpenContact] = React.useState(false);

  const showSidebar =
    pathname === "/appointment" ||
    pathname === "/report" ||
    pathname.includes("/setting") ||
    pathname === "/cars" ||
    pathname === "/training-request";

  const handleClick = (status: string) => {
    if (sortValue === status) {
      dispatch(sortValueSet("All Message"));
    } else {
      dispatch(sortValueSet(status));
    }
  };

  const handleContact = () => {
    setOpenContact(!contactOpen);
  };

  const handleFeedback = () => {
    setOpenFeedBackDialog(!openFeedBackDialog);
  };

  return (
    <React.Fragment>
      {!showSidebar && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={openSidebar ? "self-start" : "center"}
          justifyContent={"flex-end"}
          gap={2}
        >
          {[
            {
              title: "All",
              value: "All Message",
              icon: <AllIcon />,
            },
            {
              title: "Appointment",
              value: "Appointment",
              icon: <AppointmentIcon />,
            },
            {
              title: "Hot",
              value: "Hot Leads",
              icon: <HotIcon />,
            },
            {
              title: "Re-Engaged",
              value: "Not Engaged",
              icon: <ReengagedIcon />,
            },
            {
              title: "Contacted",
              value: "Contact Attempted",
              icon: <ContactAttemptedIcon />,
            },
            {
              title: "starred",
              value: "Book Marked",
              icon: <StarFillIcon />,
            },
          ].map((r: any, id) => (
            <CustomListItemButton
              key={id}
              onClick={() => handleClick(r.value)}
              sx={{
                backgroundColor: (theme: any) =>
                  sortValue === r.value ? theme.palette.primary[100] : "",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <Tooltip title={r.title}> */}
              <CustomListItemIcon
                sx={{
                  color: (theme: any) =>
                    sortValue === r.value ? theme.palette.primary.main : theme.palette.primary[200],
                }}
              >
                {r.icon}
              </CustomListItemIcon>
              {/* </Tooltip> */}
              <Typography sx={{ fontSize: "10px" }} fontWeight={700}>
                {r.title}
              </Typography>
              {openSidebar && <ListItemText primary={r.title} sx={{ color: "#0000008c" }} />}
            </CustomListItemButton>
          ))}
          <Divider />
        </Box>
      )}

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        height={"100%"}
      >
        {isCustomLayout && (
          <>
            <Divider />
            <CustomListItemButton onClick={() => navigate("/cars")}>
              <CustomListItemIcon>
                <DirectionsCarRoundedIcon color="primary" />
                <Typography variant="caption">Cars</Typography>
              </CustomListItemIcon>
            </CustomListItemButton>
            <CustomListItemButton onClick={() => navigate("/setting")}>
              <CustomListItemIcon>
                <SettingsRoundedIcon color="primary" />
                <Typography variant="caption">Setting</Typography>
              </CustomListItemIcon>
            </CustomListItemButton>
            <CustomListItemButton onClick={handleFeedback}>
              <CustomListItemIcon>
                <FeedbackIcon color="primary" />
                <Typography variant="caption">Feedback</Typography>
              </CustomListItemIcon>
            </CustomListItemButton>
            <Divider />
          </>
        )}
        <CustomListItemButton onClick={() => navigate("/setting/dealership")}>
          <CustomListItemIcon>
            <SettingsRoundedIcon color="primary" />
            <Typography variant="caption">Setting</Typography>
          </CustomListItemIcon>
        </CustomListItemButton>
        <CustomListItemButton onClick={() => navigate("/training-request")}>
          <CustomListItemIcon>
            <ModelTrainingRoundedIcon color="primary" />
            <Typography variant="caption">Training</Typography>
          </CustomListItemIcon>
        </CustomListItemButton>
        <CustomListItemButton onClick={handleFeedback}>
          <CustomListItemIcon>
            <FeedbackIcon color="primary" />
            <Typography variant="caption">Feedback</Typography>
          </CustomListItemIcon>
        </CustomListItemButton>
        <CustomListItemButton onClick={handleContact}>
          <CustomListItemIcon>
            <ContactMailIcon color="primary" />
            <Typography variant="caption">Contact Us</Typography>
          </CustomListItemIcon>
        </CustomListItemButton>
      </Box>

      {contactOpen && <ContactUs open={contactOpen} handleClose={() => setOpenContact(false)} />}
      <Feedback open={openFeedBackDialog} handleClose={handleFeedback} />
    </React.Fragment>
  );
};

export default LeftSidebar;
